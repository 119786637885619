import React, { Component } from 'react';
import { connect } from 'react-redux';

export class NoMatch extends Component {    
    render() {
        return (
            <div className="main">
                <p className="title">404 Page!</p>
            </div>
        );
    }
}

NoMatch.propTypes = {};

export default connect()(NoMatch)
