import React, { Component } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';

import './rsvp.css';
import { sendRSVP } from '../store/home/action';

class RSVP extends Component {

    statusOptions = [
        { value: 'COMING', label: 'Coming' },
        { value: 'TENTATIVE', label: 'Tentative' },
        { value: 'HATESUS', label: 'Can\'t Make it' }
    ];

    foodOptions = [
        { value: 'Beef', label: 'Beef' },
        { value: 'Chichin', label: 'Chichin' },
        { value: 'Scrimp', label: 'Scrimp' },
    ]

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            comment: '',
            foodSelections: [this.foodOptions[0]],
            status: this.statusOptions[0],
        };
    
        this.updateName = this.updateName.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.addPerson = this.addPerson.bind(this);
    }

    updateName(event) {
        this.setState({name: event.target.value});
    }

    updateEmail(event) {
        this.setState({email: event.target.value});
    }

    updatePassword(event) {
        this.setState({password: event.target.value});
    }

    updateStatus = (selectedOption) => {
        this.setState({ status: selectedOption });
    };

    updateComment = (event) => {
        this.setState({ comment: event.target.value });
    };

    handleSubmit(event) {
        event.preventDefault();
        this.props.sendRSVP(this.state);
    }

    disabledSubmit() {
        return this.state.name === '' ||
               this.state.email === '' ||
               this.state.password === ''
    }

    addPerson(event) {
        this.setState((state) => {
            return {foodSelections: [...state.foodSelections, this.foodOptions[0]]}
        })
    }

    removePerson(index) {
        this.setState(state => {
            state.foodSelections.splice(index, 1);
            return {
                foodSelections: state.foodSelections
            }
        })
    }

    foodSelectionForm (foodSelections) {
        return (
            <div id="accordion">
                {foodSelections.map((selection, i) => {
                    const personNumber = i + 1
                    const idName = `collapse${personNumber}`
                    return (
                        <div className="card" key={i}>
                            <div className="card-header" id="headingOne">
                                <h4 className="mb-0">
                                    <button type="button" className="btn collapsed col-xs-10 col-lg-11" data-toggle="collapse" data-target={`#${idName}`} aria-expanded="false" aria-controls={idName}>
                                        Person #{personNumber}
                                    </button>
                                    <button type="button" onClick={() => this.removePerson(i)} className="btn btn-danger collapsed col-xs-2 col-lg-1">
                                        -
                                    </button>
                                </h4>
                            </div>
                            <div id={idName} className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    {this.foodOptions.map(foodOption => {
                                        return (
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked={selection === foodOption} onChange={console.log}/>
                                                    {foodOption.label}
                                                </label>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    render() {
        const { selectedStatus, foodSelections} = this.state;
        return (
            <form id="rsvp_form" className="col-xs-10 col-lg-6" onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <label htmlFor="nameInput">Name</label>
                    <input className={"form-control"} id={"nameInput"} required type="text" value={this.state.name} onChange={this.updateName} />
                </div>
                <div className="form-group">
                    <label htmlFor="emailInput">Email</label>
                    <input required id="emailInput" className={"form-control"} type="email" value={this.state.email} onChange={this.updateEmail} />
                </div>
                <div className="form-group">
                    <label htmlFor="passwordInput">Password</label>
                    <input required id={"passwordInput"} className={"form-control"} type="password" value={this.state.password} onChange={this.updatePassword} />
                </div>
                <div className="form-group">
                    <label htmlFor="commentInput">Comment</label>
                    <textarea cols="40" rows="5" id={"commentInput"} className={"form-control"} value={this.state.comment} onChange={this.updateComment} />
                </div>
                <div className="form-group">
                    <label htmlFor="statusInput">Status</label>
                    <Select
                        id={"statusInput"}
                        defaultValue={this.statusOptions[0]}
                        value={selectedStatus}
                        onChange={this.updateStatus}
                        options={this.statusOptions}
                    />
                </div>
                {/* <div className="form-group">
                    <label htmlFor="accordian">Food Selections</label>

                    {this.foodSelectionForm(foodSelections)}
                </div> */}
                {/* <button type="button" onClick={this.addPerson} className="btn btn-success collapsed col-3 float-left" >
                    Add Person
                </button> */}
                <button disabled={this.disabledSubmit()} className="submit-button btn btn-primary float-right" type="submit">Submit</button>
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        show_thanks: state.home.show_thanks
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendRSVP: (state) => dispatch(sendRSVP(state.name, state.email, state.password, state.status.value, state.comment))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RSVP)
