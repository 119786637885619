import { createStore, combineReducers, applyMiddleware } from "redux";
import home from './home/reducer'
import thunk from 'redux-thunk';

const rootReducer = combineReducers({
    home
});

const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
);

export default store;