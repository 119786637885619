import React, { Component } from 'react';

import './castle.css';

class Castle extends Component {
    render() {
        return (
            <div className="container">
                <div className="row justify-content-center">
                    Duns Castle, Duns TD11 3NW, UK
                </div>
                <div className="row justify-content-center">
                    <img className="castle col-xs-12 col-lg-6" src="outside.jpg" alt="The Castle!"></img>
                </div>
                <div className="row justify-content-center">
                <a href="https://dunscastle.co.uk">dunscastle.co.uk</a>
                </div>
            </div>
        );
    }
}

export default Castle