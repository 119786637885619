import React, { Component } from 'react';

import './wedding.css';

class Wedding extends Component {
    render() {
        return (
            <div className="container wedding-content-container">
            <div className="row wedding-content">
                <div className="card col-xs-12 col-lg-4">
                    <img className="card-img-top" src="gate.jpg" alt="A Gate!"></img>
                    <div className="card-body">
                        <h5 className="card-title">Friday Night</h5>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">Dinner in the castle</li>
                            <li className="list-group-item">Scottish history tour</li>
                            <li className="list-group-item">Cake</li>
                        </ul>
                    </div>
                </div> 
                <div className="card col-xs-12 col-lg-4">
                    <img className="card-img-top" src="falconry.jpg" alt="A Falcon!"></img>
                    <div className="card-body">
                        <h5 className="card-title">Saturday</h5>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">Breakfast in the castle</li>
                            <li className="list-group-item">Highland games</li>
                            <li className="list-group-item">Archery</li>
                            <li className="list-group-item">Light lunch served at the castle</li>
                            <li className="list-group-item">Wedding ceremony @ 3pm</li>
                            <li className="list-group-item">Falconry on the grounds</li>
                            <li className="list-group-item">Reception with dinner</li>
                            <li className="list-group-item">Fireworks at night</li>
                        </ul>
                    </div>
                </div> 
                <div className="card col-xs-12 col-lg-4">
                    <img className="card-img-top" src="inside.jpeg" alt="A thingy!"></img>
                    <div className="card-body">
                        <h5 className="card-title">Sunday Morning</h5>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">Breakfast in the castle</li>
                            <li className="list-group-item">Bride and groom depart</li>
                        </ul>
                    </div>
                </div> 
            </div>
            </div>
        );
    }
}

export default Wedding