import * as types from './actionTypes';
import axios from 'axios'
import { toast } from 'react-toastify';

axios.interceptors.request.use(function (config) {
    config.headers['x-api-key'] =  '5AXkAt20wz90lYvnMDGnM416ZK2kyLfp1rbvr1Fs'; // super secure!
    return config;
});

export function sendRSVP(name, email, password, status, comment) {
    return (dispatch) => {
        axios.put('https://0q4fzdgrea.execute-api.us-east-1.amazonaws.com/default/rsvp', {
            name,
            emailAddress: email,
            password,
            status,
            comment: comment || "No Comment"
        })
        .then(() => {
            dispatch({type: types.RSVP_SUCCESS, payload: {}});
            toast.success('RSVP successfully submitted!', {
                containerId: 'RSVP',
                className: "success-toastify"
            })
        })
        .catch((err) => {
            console.log(err);
            toast.error('RSVP unsuccessfully submitted...', {
                containerId: 'RSVP',
                className: "fail-toastify"
            })
        });
    }
}
