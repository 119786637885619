import * as types from './actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    rsvp_done: false,
    show_thanks: false,
});

export default function reduce(state = initialState, action) {
    switch (action.type) {
        case types.STOP_SHOWING_THANKS:
            return {
                ...state,
                show_thanks: false,
            };
        case types.RSVP_SUCCESS:
            return {
                ...state,
                rsvp_done: true,
                show_thanks: true,
            };
        default:
            return state;
    }
}
