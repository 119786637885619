import React from 'react';
import { Switch, Route, BrowserRouter as Router} from 'react-router-dom';
import Home from '../home';
import NoMatch from '../no_match';
function Main() {  
    return (
        <div className="main-wrapper">
            <Router>
                <main className="main-content">
                    <Switch>
                        <Route path="/" component={Home} />
                        <Route component={NoMatch} />
                    </Switch>
                </main>
            </Router>
        </div>
    );
}

export default Main;
