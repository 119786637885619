import React, { Component } from 'react';

import './carousel.css';

class Carousel extends Component {
    render() {
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div id="carousel" className="col-sm-10 col-md-8 col-lg-6 carousel slide container" data-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src="castle.jpg" alt="Duns Castle"></img>
                            </div>
                            <div className="carousel-item">
                                <img src="mitchell.jpg" alt="Mt. Mitchell"></img>
                            </div>
                            <div className="carousel-item">
                                <img src="castle3.jpg" alt="Duns Castle"></img>
                            </div>
                            <div className="carousel-item">
                                <img src="denver.jpg" alt="Denver"></img>
                            </div>
                            <div className="carousel-item">
                                <img src="inside4.jpg" alt="Duns Castle"></img>
                            </div>
                            <div className="carousel-item">
                                <img src="cloudland.jpg" alt="Cloudland Canyon"></img>
                            </div>
                            <div className="carousel-item">
                                <img src="lake.jpg" alt="Duns Estate"></img>
                            </div>
                            <div className="carousel-item">
                                <img src="charleston.jpg" alt="Charleston, SC"></img>
                            </div>
                        </div>

                        
                        <a className="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carousel" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Carousel