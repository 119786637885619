import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import './navbar.scss';

class NavBar extends Component {
    render() {
        return (
            <nav id="navigation" className="container-fluid navbar-container">
                <div className="row justify-content-center navbar">
                    <Link className="nav-item nav-link col-xs-12 col-lg-3 text-center" to="/wedding">THE WEDDING</Link>
                    <Link className="nav-item nav-link col-xs-12 col-lg-3 text-center" to="/castle">DUNS CASTLE</Link>
                    <Link className="nav-item nav-link col-xs-12 col-lg-3 text-center" to="/registry">REGISTRY</Link>
                    <Link className="nav-item nav-link col-xs-12 col-lg-3 text-center" to="/rsvp">RSVP</Link>
                </div>
            </nav>
        );
    }
}

export default NavBar