import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Switch, Route} from "react-router-dom";
import { Link } from 'react-router-dom';

import NavBar from '../../components/navbar';
import Carousel from '../../components/carousel';
import RSVP from '../../components/rsvp';
import Wedding from '../../components/wedding';
import Castle from '../../components/castle';
import Registry from '../../components/registry';

import './index.css';

export class Home extends Component {    
    render() {
        return (
            <div>
                <div id="background"/>
                <div id={"main_container"} className="container-fluid">
                    <div id={"header-wrapper"} className={"row justify-content-center"}>
                        <Link className={"col-12"} to="/">
                            <h1 id="header" className="display-4 text-center"> Mary and Brandon</h1>
                        </Link>
                        <p id="info" className="lead">Duns Castle, Scotland ♥  October 11-13th </p>
                    </div>
                    <div id="nav-wrapper" className={"row justify-content-center white-background"}>
                        <NavBar />
                    </div>
                    <div id="switch_container" className={"row justify-content-center white-background"}>
                        <Switch>
                            <Route path="/registry" component={Registry}/>
                            <Route path="/castle" component={Castle}/>
                            <Route path="/wedding" component={Wedding}/>
                            <Route path="/rsvp" component={RSVP}/>
                            <Route component={Carousel}/>
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

Home.propTypes = {};

export default connect()(Home)
