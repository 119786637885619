import React, { Component } from 'react';

import './registry.css';

class Registry extends Component {
    render() {
        return (
            <div className="container">
                <div className="info row justify-content-center text-center"> 
                    <h2>Your presence is the only present desired!</h2>
                </div>
            </div>
        );
    }
}

export default Registry